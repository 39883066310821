var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"CfbParameter4"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数04_1")]),_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])]),_vm._m(0),_c('div',{staticClass:"context flex"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow shadow1"},[_c('div',{staticClass:"words"},[_vm._v("床温MAX预测模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-CYZQ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.BYMNL71,
                    'E01-CYZQ',
                    'BYMNL71',
                    _vm.infoList.BYMNL71_node_string
                  )}}},[_vm._v(" "+_vm._s("BYMNL71" in _vm.infoList ? _vm.infoList.BYMNL71 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-LBSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.BYMNL72,
                    'E01-LBSJ',
                    'BYMNL72',
                    _vm.infoList.BYMNL72_node_string
                  )}}},[_vm._v(" "+_vm._s("BYMNL72" in _vm.infoList ? _vm.infoList.BYMNL72 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-YCSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.BYMNL73,
                    'E01-YCSJ',
                    'BYMNL73',
                    _vm.infoList.BYMNL73_node_string
                  )}}},[_vm._v(" "+_vm._s("BYMNL73" in _vm.infoList ? _vm.infoList.BYMNL73 : 0)+" ")])])]),_c('div',[_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-MCWPV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'MAXCW',
                    _vm.infoList.MAXCW_node_string,
                    'MAXCWVV1',
                    '床温最大值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MAXCW,
                    'MAXCW',
                    _vm.infoList.MAXCW_node_string,
                    'MAXCWVV1'
                  )}}},[_vm._v(" "+_vm._s("MAXCW" in _vm.infoList ? _vm.infoList.MAXCW : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-YCMCWPV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PV',
                    _vm.infoList.XK04_node_string,
                    'XK04_PVVV1',
                    'XK04测量值',
                    'XK04'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.XK04.PV,
                    'PV',
                    _vm.infoList.XK04_node_string,
                    'XK04_PVVV1'
                  )}}},[_vm._v(" "+_vm._s("XK04" in _vm.infoList ? _vm.infoList.XK04.PV : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-MCWSPV")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.CWGCH,
                    'E01-MCWSPV',
                    'CWGCH',
                    _vm.infoList.CWGCH_node_string
                  )}}},[_vm._v(" "+_vm._s("CWGCH" in _vm.infoList ? _vm.infoList.CWGCH : 0)+" ")])])])]),_c('div',{staticClass:"location2 flex"},[_c('div',{staticClass:"shadow shadow1"},[_c('div',{staticClass:"words"},[_vm._v("汽压预测模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E02-CYZQ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.BYMNL68,
                    'E02-CYZQ',
                    'BYMNL68',
                    _vm.infoList.BYMNL68_node_string
                  )}}},[_vm._v(" "+_vm._s("BYMNL68" in _vm.infoList ? _vm.infoList.BYMNL68 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E02-LBSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.BYMNL69,
                    'E02-LBSJ',
                    'BYMNL69',
                    _vm.infoList.BYMNL69_node_string
                  )}}},[_vm._v(" "+_vm._s("BYMNL69" in _vm.infoList ? _vm.infoList.BYMNL69 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E02-YCSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.BYMNL70,
                    'E02-YCSJ',
                    'BYMNL70',
                    _vm.infoList.BYMNL70_node_string
                  )}}},[_vm._v(" "+_vm._s("BYMNL70" in _vm.infoList ? _vm.infoList.BYMNL70 : 0)+" ")])])]),_c('div',[_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-ZQPPV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PV',
                    _vm.infoList.XK03_node_string,
                    'XK03_PVVV1',
                    'XK03测量值',
                    'XK03'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.XK03.PV,
                    'PV',
                    _vm.infoList.XK03_node_string,
                    'XK03_PVVV1'
                  )}}},[_vm._v(" "+_vm._s("XK03" in _vm.infoList ? _vm.infoList.XK03.PV : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-ZQPYCPV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PV',
                    _vm.infoList.XK03_node_string,
                    'XK03_PVVV1',
                    'XK03测量值',
                    'XK03'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.XK03.PV,
                    'PV',
                    _vm.infoList.XK03_node_string,
                    'XK03_PVVV1'
                  )}}},[_vm._v(" "+_vm._s("XK03" in _vm.infoList ? _vm.infoList.XK03.PV : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-ZQPSP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.XK03.SP,
                    'E01-ZQPSP',
                    'SP',
                    _vm.infoList.XK03_node_string
                  )}}},[_vm._v(" "+_vm._s("XK03" in _vm.infoList ? _vm.infoList.XK03.SP : 0)+" ")])])])]),_c('div',{staticClass:"shadow shadow3"},[_c('div',{staticClass:"words"},[_vm._v("煤量优化模型")]),_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-TOP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.TOP,
                      'E03-TOP',
                      'TOP',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string
                    )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.TOP : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-SOP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.SOP20,
                      'E03-SOP',
                      'SOP20',
                      _vm.infoList.SOP20_node_string
                    )}}},[_vm._v(" "+_vm._s("SOP20" in _vm.infoList ? _vm.infoList.SOP20 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-EOP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.EOP20,
                      'E03-EOP',
                      'EOP20',
                      _vm.infoList.EOP20_node_string
                    )}}},[_vm._v(" "+_vm._s("EOP20" in _vm.infoList ? _vm.infoList.EOP20 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-SFK")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.K21,
                      'E03-SFK',
                      'K21',
                      _vm.infoList.K21_node_string
                    )}}},[_vm._v(" "+_vm._s("K21" in _vm.infoList ? _vm.infoList.K21 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-OH")]),_c('div',{staticClass:"column3"},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL .YHZL_H : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-TC(m)")]),_c('div',{staticClass:"column2",on:{"click":function($event){_vm.toIpt(
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.YHJG,
                      'E03-TC(m)',
                      'YHJG',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string
                    )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL .YHJG : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-SYT(m)")]),_c('div',{staticClass:"column3"},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL .YHJGSYSJ : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-PYZ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.BYMNL17,
                      'E03-PYZ',
                      'BYMNL17',
                      _vm.infoList.BYMNL17_node_string
                    )}}},[_vm._v(" "+_vm._s("BYMNL17" in _vm.infoList ? _vm.infoList.BYMNL17 : 0)+" ")])])]),_c('div',{staticClass:"location1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("常规负荷")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.F0,
                      '常规负荷',
                      'F0',
                      _vm.infoList.F0_node_string
                    )}}},[_vm._v(" "+_vm._s("F0" in _vm.infoList ? _vm.infoList.F0 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-SOPSJ")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SOP',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string,
                      'SOP2VV1',
                      '优化步长',
                      'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.SOP,
                      'SOP',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string,
                      'SOP2VV1'
                    )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.SOP : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-EOPSJ")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'EOP',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string,
                      'EOP2VV1',
                      '优化精度',
                      'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.EOP,
                      'EOP',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string,
                      'EOP2VV1'
                    )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.EOP : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-EFK")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.K22,
                      'E03-EFK',
                      'K22',
                      _vm.infoList.K22_node_string
                    )}}},[_vm._v(" "+_vm._s("K22" in _vm.infoList ? _vm.infoList.K22 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-0L")]),_c('div',{staticClass:"column3"},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL .YHZL_L : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-0T")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'AV_FD__ll__2__rr__',
                      _vm.infoList.CWFH_node_string,
                      'AV2VV1',
                      '优化增量输出',
                      'CWFH'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.CWFH.AV_FD__ll__2__rr__,
                      'AV_FD__ll__2__rr__',
                      _vm.infoList.CWFH_node_string,
                      'AV2VV1'
                    )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.AV_FD__ll__2__rr__ : 0)+" ")])]),_vm._m(1)])])]),_c('div',{staticClass:"shadow shadow4 flex"},[_c('div',[_c('div',{staticClass:"words"},[_vm._v("煤量优化模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E04-FHH")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.ABY3,
                    'E04-FHH',
                    'ABY3',
                    _vm.infoList.ABY3_node_string
                  )}}},[_vm._v(" "+_vm._s("ABY3" in _vm.infoList ? _vm.infoList.ABY3 : 0)+" ")])])]),_c('div',{staticClass:"location1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E04-ZQPPV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'BYMNL39',
                    _vm.infoList.BYMNL39_node_string,
                    'BYMNL39VV1',
                    '主蒸汽压力平均值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.BYMNL39,
                    'BYMNL39',
                    _vm.infoList.BYMNL39_node_string,
                    'BYMNL39VV1'
                  )}}},[_vm._v(" "+_vm._s("BYMNL39" in _vm.infoList ? _vm.infoList.BYMNL39 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E04-FHL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.ABY4,
                    'E04-FHL',
                    'ABY4',
                    _vm.infoList.ABY4_node_string
                  )}}},[_vm._v(" "+_vm._s("ABY4" in _vm.infoList ? _vm.infoList.ABY4 : 0)+" ")])])])]),_c('div',{staticClass:"shadow shadow5"},[_c('div',{staticClass:"words"},[_vm._v("床温-主汽压力系数")]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_one"}),_c('div',{staticClass:"column1_two"}),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.IN_FD__ll__1__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__1__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.IN_FD__ll__1__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.AV_FD__ll__1__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__1__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.AV_FD__ll__1__rr__ : 0)+" ")])]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.IN_FD__ll__1__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__1__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.IN_FD__ll__1__rr__ : 0)+" ")]),_c('div',{staticClass:"column1_two"},[_vm._v("---")]),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.IN_FD__ll__2__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__2__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.IN_FD__ll__2__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.AV_FD__ll__2__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__2__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.AV_FD__ll__2__rr__ : 0)+" ")])]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.IN_FD__ll__2__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__2__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.IN_FD__ll__2__rr__ : 0)+" ")]),_c('div',{staticClass:"column1_two"},[_vm._v("---")]),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.IN_FD__ll__3__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__3__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.IN_FD__ll__3__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.AV_FD__ll__3__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__3__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.AV_FD__ll__3__rr__ : 0)+" ")])]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.IN_FD__ll__3__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__3__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.IN_FD__ll__3__rr__ : 0)+" ")]),_c('div',{staticClass:"column1_two"},[_vm._v("---")]),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.IN_FD__ll__4__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__4__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.IN_FD__ll__4__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.AV_FD__ll__4__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__4__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.AV_FD__ll__4__rr__ : 0)+" ")])]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_one"},[_vm._v(">")]),_c('div',{staticClass:"column1_two"}),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.IN_FD__ll__4__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__4__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.IN_FD__ll__4__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWFH.AV_FD__ll__5__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__5__rr__',
                  _vm.infoList.CWFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWFH" in _vm.infoList ? _vm.infoList.CWFH.AV_FD__ll__5__rr__ : 0)+" ")])])])]),_c('div',{staticClass:"two flex"},[_c('div',{staticClass:"first"},[_c('div',{staticClass:"shadow shadow1 float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E07-JBML")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.GMLLSP,
                  'E07-JBML',
                  'GMLLSP',
                  _vm.infoList.GMLLSP_node_string
                )}}},[_vm._v(" "+_vm._s("GMLLSP" in _vm.infoList ? _vm.infoList.GMLLSP : 0)+" ")])]),_c('div',{staticClass:"float2 flex"},[_c('div',{staticClass:"buttonx",style:({
                background:
                  _vm.infoList.XK04 && !_vm.infoList.XK04.SP ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK04
                  ? _vm.toDetail(
                      1,
                      'XK04',
                      _vm.infoList.XK04_node_string,
                      '',
                      '床温调节'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"shadow shadow1 location1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E01-MCWAV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.XK04_node_string,
                    'XK04_AVVV1',
                    'XK04总输出',
                    'XK04'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.XK04.AV,
                    'AV',
                    _vm.infoList.XK04_node_string,
                    'XK04_AVVV1'
                  )}}},[_vm._v(" "+_vm._s("XK04" in _vm.infoList ? _vm.infoList.XK04.AV : 0)+" ")])])]),_c('div',{staticClass:"float3 flex"},[_c('div',{staticClass:"buttonx",style:({
                background:
                  _vm.infoList.XK0O && !_vm.infoList.XK0O.SP ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK0O
                  ? _vm.toDetail(
                      1,
                      'XK0O',
                      _vm.infoList.XK0O_node_string,
                      '',
                      '主汽压力广义预测调节先控'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"shadow shadow1 location2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E14-MXXYSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MX_XYSJ,
                    'E14-MXXYSJ',
                    'MX_XYSJ',
                    _vm.infoList.MX_XYSJ_node_string
                  )}}},[_vm._v(" "+_vm._s("MX_XYSJ" in _vm.infoList ? _vm.infoList.MX_XYSJ : 0)+" ")])])]),_c('div',{staticClass:"float3 flex"},[_c('div',{staticClass:"buttonx",style:({
                background:
                  _vm.infoList.XK0P && !_vm.infoList.XK0P.SP ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK0P
                  ? _vm.toDetail(
                      1,
                      'XK0P',
                      _vm.infoList.XK0P_node_string,
                      '',
                      '广义预测流量偏差调节先控'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"shadow shadow1 location2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E14-MXYCSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MX_YCSJ,
                    'E14-MXXYSJ',
                    'MX_YCSJ',
                    _vm.infoList.MX_YCSJ_node_string
                  )}}},[_vm._v(" "+_vm._s("MX_YCSJ" in _vm.infoList ? _vm.infoList.MX_YCSJ : 0)+" ")])])]),_c('div',{staticClass:"float4 flex"},[_c('div',{staticClass:"buttonx",style:({
                background:
                  _vm.infoList.XK03 && !_vm.infoList.XK03.SP ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK03
                  ? _vm.toDetail(
                      1,
                      'XK03',
                      _vm.infoList.XK03_node_string,
                      '',
                      '主汽压力调节'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"shadow shadow1 location1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E02-ZQPAV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.XK03_node_string,
                    'XK03_AVVV1',
                    'XK03总输出',
                    'XK03'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.XK03.AV,
                    'AV',
                    _vm.infoList.XK03_node_string,
                    'XK03_AVVV1'
                  )}}},[_vm._v(" "+_vm._s("XK03" in _vm.infoList ? _vm.infoList.XK03.AV : 0)+" ")])])]),_c('div',{staticClass:"float5 flex"},[_c('div',{staticClass:"buttonr",style:({
                background:
                  _vm.infoList.QK07 && !_vm.infoList.QK07.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK07
                  ? _vm.toDetail(
                      2,
                      'QK07',
                      _vm.infoList.QK07_node_string,
                      '',
                      '主汽流量前馈'
                    )
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"shadow shadow1 location1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E08-PV4")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'FT108D_B',
                    _vm.infoList.FT108D_B_node_string,
                    'FT108D_BVV1',
                    '汽机电负荷'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.FT108D_B,
                    'FT108D_B',
                    _vm.infoList.FT108D_B_node_string,
                    'FT108D_BVV1'
                  )}}},[_vm._v(" "+_vm._s("FT108D_B" in _vm.infoList ? _vm.infoList.FT108D_B : 0)+" ")])])]),_c('div',{staticClass:"float6 flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-OE")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'OBCS',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'OE2VV1',
                      '优化过程上次值',
                      'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL.OBCS,
                      'OBCS',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'OE2VV1'
                    )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL .OBCS : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E03-NE")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'NBCS',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'NE2VV1',
                      '优化过程本次值',
                      'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL.NBCS,
                      'NBCS',
                      _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'NE2VV1'
                    )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL .NBCS : 0)+" ")])])]),_c('div',{staticClass:"button3"},[_c('div',{staticClass:"button3_word",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.RESET,
                    '按钮',
                    'RESET',
                    _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string
                  )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.RESET ? "强制优化" : "自动优化" : 0)+" ")])])]),_c('div',{staticClass:"float7 flex"},[_c('div',{staticClass:"buttonr",style:({
                background:
                  _vm.infoList.RSF03 && !_vm.infoList.RSF03.SP ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF03
                  ? _vm.toDetail(
                      3,
                      'RSF03',
                      _vm.infoList.RSF03_node_string,
                      '',
                      '运行炉压力软伺服'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"shadow shadow1 location1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E04-FHAV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.RSF03_node_string,
                    'RSF03_AVVV1',
                    'RSF03输出值',
                    'RSF03'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF03.AV,
                    'AV',
                    _vm.infoList.RSF03_node_string,
                    'RSF03_AVVV1'
                  )}}},[_vm._v(" "+_vm._s("RSF03" in _vm.infoList ? _vm.infoList.RSF03.AV : 0)+" ")])])]),_c('div',{staticClass:"float8 flex"},[_c('div',{staticClass:"buttonr",style:({
                background:
                  _vm.infoList.RSF0C && !_vm.infoList.RSF0C.SP ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF0C
                  ? _vm.toDetail(
                      3,
                      'RSF0C',
                      _vm.infoList.RSF0C_node_string,
                      '',
                      '软伺服'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"words location1"},[_vm._v("多炉协调控制模型")])]),_c('div',{staticClass:"shadow shadow2"},[_c('div',{staticClass:"words"},[_vm._v("前馈脉冲重叠模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E05-KG")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.QKMCFOP1.KG,
                    'E05-KG',
                    'KG',
                    _vm.infoList.QKMCFOP1_node_string
                  )}}},[_vm._v("0.00 ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E05-TC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.QKMCFOP1.TC,
                    'E05-TC',
                    'TC',
                    _vm.infoList.QKMCFOP1_node_string
                  )}}},[_vm._v("0.00 ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E05-DI")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.QKMCFOP1.DI,
                    'E05-DI',
                    'DI',
                    _vm.infoList.QKMCFOP1_node_string
                  )}}},[_vm._v("0.00 ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("E05-AV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.QKMCFOP1_node_string,
                    'QKMCFOP_AVVV1',
					'',
					'QKMCFOP1'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QKMCFOP1.AV,
                    'AV',
                    _vm.infoList.QKMCFOP1_node_string,
                    'QKMCFOP_AVVV1'
                  )}}},[_vm._v(" 0.00 ")])])])]),_c('div',{staticClass:"second"},[_c('div',{staticClass:"button1 float1"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(_vm.infoList.XTEN, '按钮', 'XTEN', _vm.infoList.XTEN_node_string)}}},[_vm._v(" "+_vm._s("XTEN" in _vm.infoList ? (_vm.infoList.XTEN ? "投用" : "切除") : 0)+" ")])]),_c('div',{staticClass:"button1 float2"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(_vm.infoList.GYYC, '按钮', 'GYYC', _vm.infoList.GYYC_node_string)}}},[_vm._v(" "+_vm._s("GYYC" in _vm.infoList ? _vm.infoList.GYYC ? "预测投用" : "预测切除" : 0)+" ")])]),_c('div',{staticClass:"button1 float3"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(_vm.infoList.XTEN, '按钮', 'XTEN', _vm.infoList.XTEN_node_string)}}},[_vm._v(" "+_vm._s("XTEN" in _vm.infoList ? (_vm.infoList.XTEN ? "投用" : "切除") : 0)+" ")])]),_c('div',{staticClass:"button1 float4"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.BYKGL28,
                  '按钮',
                  'BYKGL28',
                  _vm.infoList.BYKGL28_node_string
                )}}},[_vm._v(" "+_vm._s("BYKGL28" in _vm.infoList ? (_vm.infoList.BYKGL28 ? "投用" : "切除") : 0)+" ")])]),_c('div',{staticClass:"button1 float5"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr__.BCSYHQY,
                  '按钮',
                  'BCSYHQY',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr___node_string
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__2__rr__" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr__.BCSYHQY ? "优化投用" : "优化切除" : 0)+" ")])]),_c('div',{staticClass:"button1 float6"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(_vm.infoList.XTEN, '按钮', 'XTEN', _vm.infoList.XTEN_node_string)}}},[_vm._v(" "+_vm._s("XTEN" in _vm.infoList ? (_vm.infoList.XTEN ? "调节炉" : "运行炉") : 0)+" ")])]),_c('div',{staticClass:"button1 float7"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.GLXTEN,
                  '按钮',
                  'GLXTEN',
                  _vm.infoList.GLXTEN_node_string
                )}}},[_vm._v(" "+_vm._s("GLXTEN" in _vm.infoList ? _vm.infoList.GLXTEN ? "多炉协调" : "协调切除" : 0)+" ")])]),_c('div',{staticClass:"button1 float8"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.QKMCFOPEN,
                  '按钮',
                  'QKMCFOPEN',
                  _vm.infoList.QKMCFOPEN_node_string
                )}}},[_vm._v(" "+_vm._s("QKMCFOPEN" in _vm.infoList ? _vm.infoList.QKMCFOPEN ? "多炉协调" : "协调切除" : 0)+" ")])])]),_c('div',{staticClass:"third"},[_c('div',{staticClass:"shadow shadow1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-MLTC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.TLB01,
                  'E12-MLTC',
                  'TLB01',
                  _vm.infoList.TLB01_node_string
                )}}},[_vm._v(" "+_vm._s("TLB01" in _vm.infoList ? _vm.infoList.TLB01 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-MLPV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'PV',
                  _vm.infoList.PID08_node_string,
                  'PID08_PVVV1',
                  'PID08测量值',
                  'PID08'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.PID08.PV,
                  'PV',
                  _vm.infoList.PID08_node_string,
                  'PID08_PVVV1'
                )}}},[_vm._v(" "+_vm._s("PID08" in _vm.infoList ? _vm.infoList.PID08.PV : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-MLSP")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SP',
                  _vm.infoList.PID08_node_string,
                  'PID08_SPVV1',
                  '给煤流量调节',
                  'PID08'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.PID08.SP,
                  'SP',
                  _vm.infoList.PID08_node_string,
                  'PID08_SPVV1'
                )}}},[_vm._v(" "+_vm._s("PID08" in _vm.infoList ? _vm.infoList.PID08.SP : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2"},[_c('div',{staticClass:"words"},[_vm._v("氧量纠偏模型")]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E10-YCC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.O2PC_SP,
                    'E10-YCC',
                    'O2PC_SP',
                    _vm.infoList.O2PC_SP_node_string
                  )}}},[_vm._v(" "+_vm._s("O2PC_SP" in _vm.infoList ? _vm.infoList.O2PC_SP : 0)+" ")])]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E10-YCT")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.O2PC_T,
                    'E10-YCT',
                    'O2PC_T',
                    _vm.infoList.O2PC_T_node_string
                  )}}},[_vm._v(" "+_vm._s("O2PC_T" in _vm.infoList ? _vm.infoList.O2PC_T : 0)+" ")])]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E10-YCS")]),_c('div',{staticClass:"box1",style:({
                  background: _vm.infoList.SEL_O2 == false ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.O2BYC,
                    '按钮',
                    'O2BYC',
                    _vm.infoList.SEL_O2_node_string
                  )}}})]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E10-LJZ")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AT101AD_B',
                    _vm.infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1',
                    '烟气含氧量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.AT101AD_B,
                    'AT101AD_B',
                    _vm.infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1'
                  )}}},[_vm._v(" "+_vm._s("AT101AD_B" in _vm.infoList ? _vm.infoList.AT101AD_B : 0)+" ")])]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E10-RJZ")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AT101BD_B',
                    _vm.infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1',
                    '烟气含氧量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.AT101BD_B,
                    'AT101BD_B',
                    _vm.infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1'
                  )}}},[_vm._v(" "+_vm._s("AT101BD_B" in _vm.infoList ? _vm.infoList.AT101BD_B : 0)+" ")])]),_c('div',{staticClass:"button3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E10-LOT")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GMO2OUT_L',
                    _vm.infoList.GMO2OUT_L_node_string,
                    'GMO2OUT1VV1',
                    '床温氧量输出左给煤偏置'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.GMO2OUT_L,
                    'GMO2OUT_L',
                    _vm.infoList.GMO2OUT_L_node_string,
                    'GMO2OUT1VV1'
                  )}}},[_vm._v(" "+_vm._s("GMO2OUT_L" in _vm.infoList ? _vm.infoList.GMO2OUT_L : 0)+" ")])]),_c('div',{staticClass:"button3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E10-ROT")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GMO2OUT_R',
                    _vm.infoList.GMO2OUT_R_node_string,
                    'GMO2OUT2VV1',
                    '床温氧量输出右给煤偏置'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.GMO2OUT_R,
                    'GMO2OUT_R',
                    _vm.infoList.GMO2OUT_R_node_string,
                    'GMO2OUT2VV1'
                  )}}},[_vm._v(" "+_vm._s("GMO2OUT_R" in _vm.infoList ? _vm.infoList.GMO2OUT_R : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button1 float1"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GMO2QT,
                      '按钮',
                      'GMO2QT',
                      _vm.infoList.GMO2QT_node_string
                    )}}},[_vm._v(" "+_vm._s("GMO2QT" in _vm.infoList ? _vm.infoList.GMO2QT ? "投用" : "切除" : 0)+" ")])]),_c('div',{staticClass:"buttonr float2",style:({
                  background:
                    _vm.infoList.RSF09 && !_vm.infoList.RSF09.SP ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.RSF09
                    ? _vm.toDetail(
                        3,
                        'RSF09',
                        _vm.infoList.RSF09_node_string,
                        '',
                        '氧量纠偏软伺服'
                      )
                    : ''}}},[_vm._v(" R ")])])]),_c('div',{staticClass:"shadow shadow2 float3"},[_c('div',{staticClass:"words"},[_vm._v("床温纠偏模型")]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E11-YCC2")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.CWZYPC_SP,
                    'E11-YCC2',
                    'CWZYPC_SP',
                    _vm.infoList.CWZYPC_SP_node_string
                  )}}},[_vm._v(" "+_vm._s("CWZYPC_SP" in _vm.infoList ? _vm.infoList.CWZYPC_SP : 0)+" ")])]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E11-YCT2")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.CWZYPC_T,
                    'E11-YCT2',
                    'CWZYPC_T',
                    _vm.infoList.CWZYPC_T_node_string
                  )}}},[_vm._v(" "+_vm._s("CWZYPC_T" in _vm.infoList ? _vm.infoList.CWZYPC_T : 0)+" ")])]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E11-YCS2")]),_c('div',{staticClass:"box1",style:({
                  background: _vm.infoList.CWPCYC == false ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.CWPCYC,
                    '按钮',
                    'CWPCYC',
                    _vm.infoList.CWPCYC_node_string
                  )}}})]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E11-LJZ2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'CWJZ_Z',
                    _vm.infoList.CWJZ_Z_node_string,
                    'CWJZ_ZVV1',
                    '左侧床温均值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.CWJZ_Z,
                    'CWJZ_Z',
                    _vm.infoList.CWJZ_Z_node_string,
                    'CWJZ_ZVV1'
                  )}}},[_vm._v(" "+_vm._s("CWJZ_Z" in _vm.infoList ? _vm.infoList.CWJZ_Z : 0)+" ")])]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"column1"},[_vm._v("E11-RJZ2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'CWJZ_Y',
                    _vm.infoList.CWJZ_Y_node_string,
                    'CWJZ_YVV1',
                    '右侧床温均值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.CWJZ_Y,
                    'CWJZ_Y',
                    _vm.infoList.CWJZ_Y_node_string,
                    'CWJZ_YVV1'
                  )}}},[_vm._v(" "+_vm._s("CWJZ_Y" in _vm.infoList ? _vm.infoList.CWJZ_Y : 0)+" ")])]),_c('div',{staticClass:"button3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E11-LOT2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GMCWOUT_L',
                    _vm.infoList.GMCWOUT_L_node_string,
                    'GMCWOUT1VV1',
                    '床温偏差给煤左偏置修正'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.GMCWOUT_L,
                    'GMCWOUT_L',
                    _vm.infoList.GMCWOUT_L_node_string,
                    'GMCWOUT1VV1'
                  )}}},[_vm._v(" "+_vm._s("GMCWOUT_L" in _vm.infoList ? _vm.infoList.GMCWOUT_L : 0)+" ")])]),_c('div',{staticClass:"button3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E11-ROT2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GMCWOUT_R',
                    _vm.infoList.GMCWOUT_R_node_string,
                    'GMCWOUT2VV1',
                    '床温偏差给煤右偏置修正'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.GMCWOUT_R,
                    'GMCWOUT_R',
                    _vm.infoList.GMCWOUT_R_node_string,
                    'GMCWOUT2VV1'
                  )}}},[_vm._v(" "+_vm._s("GMCWOUT_R" in _vm.infoList ? _vm.infoList.GMCWOUT_R : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button1 float1"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GMCWQT,
                      '按钮',
                      'GMCWQT',
                      _vm.infoList.GMCWQT_node_string
                    )}}},[_vm._v(" "+_vm._s("GMCWQT" in _vm.infoList ? _vm.infoList.GMCWQT ? "投用" : "切除" : 0)+" ")])]),_c('div',{staticClass:"buttonr float2",style:({
                  background:
                    _vm.infoList.RSF02 && !_vm.infoList.RSF02.SP ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.RSF02
                    ? _vm.toDetail(
                        3,
                        'RSF02',
                        _vm.infoList.RSF02_node_string,
                        '',
                        '床温纠偏软伺服'
                      )
                    : ''}}},[_vm._v(" R ")])])])]),_c('div',{staticClass:"fourth"},[_vm._m(2),_c('div',{staticClass:"buttonx float2",style:({
              background:
                _vm.infoList.XK02 && !_vm.infoList.XK02.SP ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK02
                ? _vm.toDetail(
                    1,
                    'XK02',
                    _vm.infoList.XK02_node_string,
                    '',
                    '给煤流量调节'
                  )
                : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"fifth"},[_c('div',{staticClass:"shadow shadow1 float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E13-MZTJSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.TMLLB,
                  'E13-MZTJSJ',
                  'TMLLB',
                  _vm.infoList.TMLLB_node_string
                )}}},[_vm._v(" "+_vm._s("TMLLB" in _vm.infoList ? _vm.infoList.TMLLB : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("断煤流量设定")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.DMLL,
                  '断煤流量设定',
                  'DMLL',
                  _vm.infoList.DMLL_node_string
                )}}},[_vm._v(" "+_vm._s("DMLL" in _vm.infoList ? _vm.infoList.DMLL : 0)+" ")])])])]),_c('div',{staticClass:"three"},[_c('div',{staticClass:"button2"},[_c('div',{staticClass:"button2_word",on:{"click":function($event){return _vm.toIpt(_vm.infoList.QTDM, '按钮', 'QTDM', _vm.infoList.QTDM_node_string)}}},[_vm._v(" "+_vm._s("QTDM" in _vm.infoList ? _vm.infoList.QTDM ? "断煤处理启用" : "断煤处理切除" : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-AV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                _vm.infoList.XK02_node_string,
                'XK02_AVVV1',
                'XK02总输出',
                'XK02'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.XK02.AV,
                'AV',
                _vm.infoList.XK02_node_string,
                'XK02_AVVV1'
              )}}},[_vm._v(" "+_vm._s("XK02" in _vm.infoList ? _vm.infoList.XK02.AV : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_GM1_B',
                _vm.infoList.KF_GM1_B_node_string,
                'KF_GM1_BVV1',
                '1#给煤机反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_GM1_B,
                'KF_GM1_B',
                _vm.infoList.KF_GM1_B_node_string,
                'KF_GM1_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_GM1_B" in _vm.infoList ? _vm.infoList.KF_GM1_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_GM2_B',
                _vm.infoList.KF_GM2_B_node_string,
                'KF_GM2_BVV1',
                '2#给煤机反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_GM2_B,
                'KF_GM2_B',
                _vm.infoList.KF_GM2_B_node_string,
                'KF_GM2_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_GM2_B" in _vm.infoList ? _vm.infoList.KF_GM2_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float4 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-A03")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_GM3_B',
                _vm.infoList.KF_GM3_B_node_string,
                'KF_GM3_BVV1',
                '3#给煤机反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_GM3_B,
                'KF_GM3_B',
                _vm.infoList.KF_GM3_B_node_string,
                'KF_GM3_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_GM3_B" in _vm.infoList ? _vm.infoList.KF_GM3_B : 0)+" ")])]),_c('div',{staticClass:"buttonx float5",style:({
            background:
              'MAN1' in _vm.infoList
                ? _vm.infoList.MAN1.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN2' in _vm.infoList
                ? _vm.infoList.MAN2.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN3' in _vm.infoList
                ? _vm.infoList.MAN3.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN4' in _vm.infoList
                ? _vm.infoList.MAN4.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN23' in _vm.infoList
                ? _vm.infoList.MAN23.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN24' in _vm.infoList
                ? _vm.infoList.MAN24.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red',
          }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANGM', 6)}}},[_vm._v(" A ")]),_c('div',{staticClass:"shadow shadow1 float6 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-A04")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_GM4_B',
                _vm.infoList.KF_GM4_B_node_string,
                'KF_GM4_BVV1',
                '4#给煤机反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_GM4_B,
                'KF_GM4_B',
                _vm.infoList.KF_GM4_B_node_string,
                'KF_GM4_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_GM4_B" in _vm.infoList ? _vm.infoList.KF_GM4_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float7 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-A05")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_GM5_B',
                _vm.infoList.KF_GM5_B_node_string,
                'KF_GM5_BVV1',
                '5#给煤机反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_GM5_B,
                'KF_GM5_B',
                _vm.infoList.KF_GM5_B_node_string,
                'KF_GM5_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_GM5_B" in _vm.infoList ? _vm.infoList.KF_GM5_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float8 flex"},[_c('div',{staticClass:"column1"},[_vm._v("E12-A06")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_GM6_B',
                _vm.infoList.KF_GM6_B_node_string,
                'KF_GM6_BVV1',
                '6#给煤机反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_GM6_B,
                'KF_GM6_B',
                _vm.infoList.KF_GM6_B_node_string,
                'KF_GM6_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_GM6_B" in _vm.infoList ? _vm.infoList.KF_GM6_B : 0)+" ")])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("给煤优化控制模型")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow3_progress"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow3_num"},[_vm._v("0")]),_c('div',{staticClass:"shadow3_num shadow3_num1"},[_vm._v("100")])]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress_content"},[_c('div',{staticClass:"progress_hiden"})])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button2 float1"},[_c('div',{staticClass:"button2_word"},[_vm._v("给煤软测量模型")])])}]

export { render, staticRenderFns }