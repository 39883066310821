<template>
  <div class="total drag" v-draw id="CfbParameter4">
    <!-- 抬头标题 -->
    <div class="flex">
      <div class="title-hang flex">
        <div class="title">{{ projectData.project }}_参数04_1</div>
        <div class="icon" @click="closeCompon"></div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="main">
      <div class="main_title">给煤优化控制模型</div>
    </div>
    <!-- 主体内容 -->
    <div class="context flex">
      <div class="one">
        <div class="flex">
          <div class="shadow shadow1">
            <div class="words">床温MAX预测模型</div>
            <div class="flex">
              <div class="column1">E01-CYZQ</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.BYMNL71,
                    'E01-CYZQ',
                    'BYMNL71',
                    infoList.BYMNL71_node_string
                  )
                "
              >
                {{ "BYMNL71" in infoList ? infoList.BYMNL71 : 0 }}
              </div>
            </div>
            <div class="flex">
              <div class="column1">E01-LBSJ</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.BYMNL72,
                    'E01-LBSJ',
                    'BYMNL72',
                    infoList.BYMNL72_node_string
                  )
                "
              >
                {{ "BYMNL72" in infoList ? infoList.BYMNL72 : 0 }}
              </div>
            </div>
            <div class="flex">
              <div class="column1">E01-YCSJ</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.BYMNL73,
                    'E01-YCSJ',
                    'BYMNL73',
                    infoList.BYMNL73_node_string
                  )
                "
              >
                {{ "BYMNL73" in infoList ? infoList.BYMNL73 : 0 }}
              </div>
            </div>
          </div>
          <div>
            <div class="shadow shadow2 flex">
              <div class="column1">E01-MCWPV</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'MAXCW',
                    infoList.MAXCW_node_string,
                    'MAXCWVV1',
                    '床温最大值'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.MAXCW,
                    'MAXCW',
                    infoList.MAXCW_node_string,
                    'MAXCWVV1'
                  )
                "
              >
                {{ "MAXCW" in infoList ? infoList.MAXCW : 0 }}
              </div>
            </div>
            <div class="shadow shadow2 flex">
              <div class="column1">E01-YCMCWPV</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'PV',
                    infoList.XK04_node_string,
                    'XK04_PVVV1',
                    'XK04测量值',
                    'XK04'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.XK04.PV,
                    'PV',
                    infoList.XK04_node_string,
                    'XK04_PVVV1'
                  )
                "
              >
                {{ "XK04" in infoList ? infoList.XK04.PV : 0 }}
              </div>
            </div>
            <div class="shadow shadow2 flex">
              <div class="column1">E01-MCWSPV</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.CWGCH,
                    'E01-MCWSPV',
                    'CWGCH',
                    infoList.CWGCH_node_string
                  )
                "
              >
                {{ "CWGCH" in infoList ? infoList.CWGCH : 0 }}
              </div>
            </div>
          </div>
        </div>
        <div class="location2 flex">
          <div class="shadow shadow1">
            <div class="words">汽压预测模型</div>
            <div class="flex">
              <div class="column1">E02-CYZQ</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.BYMNL68,
                    'E02-CYZQ',
                    'BYMNL68',
                    infoList.BYMNL68_node_string
                  )
                "
              >
                {{ "BYMNL68" in infoList ? infoList.BYMNL68 : 0 }}
              </div>
            </div>
            <div class="flex">
              <div class="column1">E02-LBSJ</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.BYMNL69,
                    'E02-LBSJ',
                    'BYMNL69',
                    infoList.BYMNL69_node_string
                  )
                "
              >
                {{ "BYMNL69" in infoList ? infoList.BYMNL69 : 0 }}
              </div>
            </div>
            <div class="flex">
              <div class="column1">E02-YCSJ</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.BYMNL70,
                    'E02-YCSJ',
                    'BYMNL70',
                    infoList.BYMNL70_node_string
                  )
                "
              >
                {{ "BYMNL70" in infoList ? infoList.BYMNL70 : 0 }}
              </div>
            </div>
          </div>
          <div>
            <div class="shadow shadow2 flex">
              <div class="column1">E01-ZQPPV</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'PV',
                    infoList.XK03_node_string,
                    'XK03_PVVV1',
                    'XK03测量值',
                    'XK03'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.XK03.PV,
                    'PV',
                    infoList.XK03_node_string,
                    'XK03_PVVV1'
                  )
                "
              >
                {{ "XK03" in infoList ? infoList.XK03.PV : 0 }}
              </div>
            </div>
            <div class="shadow shadow2 flex">
              <div class="column1">E01-ZQPYCPV</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'PV',
                    infoList.XK03_node_string,
                    'XK03_PVVV1',
                    'XK03测量值',
                    'XK03'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.XK03.PV,
                    'PV',
                    infoList.XK03_node_string,
                    'XK03_PVVV1'
                  )
                "
              >
                {{ "XK03" in infoList ? infoList.XK03.PV : 0 }}
              </div>
            </div>
            <div class="shadow shadow2 flex">
              <div class="column1">E01-ZQPSP</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.XK03.SP,
                    'E01-ZQPSP',
                    'SP',
                    infoList.XK03_node_string
                  )
                "
              >
                {{ "XK03" in infoList ? infoList.XK03.SP : 0 }}
              </div>
            </div>
          </div>
        </div>
        <div class="shadow shadow3">
          <div class="words">煤量优化模型</div>
          <div class="flex">
            <div>
              <div class="flex">
                <div class="column1">E03-TOP</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.TOP,
                      'E03-TOP',
                      'TOP',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string
                    )
                  "
                >
                  {{
                    "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in infoList
                      ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.TOP
                      : 0
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-SOP</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.SOP20,
                      'E03-SOP',
                      'SOP20',
                      infoList.SOP20_node_string
                    )
                  "
                >
                  {{ "SOP20" in infoList ? infoList.SOP20 : 0 }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-EOP</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.EOP20,
                      'E03-EOP',
                      'EOP20',
                      infoList.EOP20_node_string
                    )
                  "
                >
                  {{ "EOP20" in infoList ? infoList.EOP20 : 0 }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-SFK</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.K21,
                      'E03-SFK',
                      'K21',
                      infoList.K21_node_string
                    )
                  "
                >
                  {{ "K21" in infoList ? infoList.K21 : 0 }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-OH</div>
                <div class="column3">
                  {{
                    "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in infoList
                      ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL
                          .YHZL_H
                      : 0
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-TC(m)</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.YHJG,
                      'E03-TC(m)',
                      'YHJG',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string
                    )
                  "
                >
                  {{
                    "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in infoList
                      ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL
                          .YHJG
                      : 0
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-SYT(m)</div>
                <div class="column3">
                  <!-- @click="toIpt(infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL.YHJGSYSJ,'E03-SYT(m)','YHJGSYSJ',infoList._node_string)" -->
                  {{
                    "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL" in infoList
                      ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL
                          .YHJGSYSJ
                      : 0
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-PYZ</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.BYMNL17,
                      'E03-PYZ',
                      'BYMNL17',
                      infoList.BYMNL17_node_string
                    )
                  "
                >
                  {{ "BYMNL17" in infoList ? infoList.BYMNL17 : 0 }}
                </div>
              </div>
            </div>
            <div class="location1">
              <div class="flex">
                <div class="column1">常规负荷</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.F0,
                      '常规负荷',
                      'F0',
                      infoList.F0_node_string
                    )
                  "
                >
                  {{ "F0" in infoList ? infoList.F0 : 0 }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-SOPSJ</div>
                <div
                  class="column3"
                  @click="
                    toCompon(
                      2,
                      'SOP',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string,
                      'SOP2VV1',
                      '优化步长',
                      'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.SOP,
                      'SOP',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string,
                      'SOP2VV1'
                    )
                  "
                >
                  {{
                    "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in infoList
                      ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.SOP
                      : 0
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-EOPSJ</div>
                <div
                  class="column3"
                  @click="
                    toCompon(
                      2,
                      'EOP',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string,
                      'EOP2VV1',
                      '优化精度',
                      'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.EOP,
                      'EOP',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string,
                      'EOP2VV1'
                    )
                  "
                >
                  {{
                    "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in infoList
                      ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.EOP
                      : 0
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-EFK</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.K22,
                      'E03-EFK',
                      'K22',
                      infoList.K22_node_string
                    )
                  "
                >
                  {{ "K22" in infoList ? infoList.K22 : 0 }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-0L</div>
                <div class="column3">
                  {{
                    "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in infoList
                      ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL
                          .YHZL_L
                      : 0
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-0T</div>
                <div
                  class="column3"
                  @click="
                    toCompon(
                      2,
                      'AV_FD__ll__2__rr__',
                      infoList.CWFH_node_string,
                      'AV2VV1',
                      '优化增量输出',
                      'CWFH'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.CWFH.AV_FD__ll__2__rr__,
                      'AV_FD__ll__2__rr__',
                      infoList.CWFH_node_string,
                      'AV2VV1'
                    )
                  "
                >
                  {{
                    "CWFH" in infoList ? infoList.CWFH.AV_FD__ll__2__rr__ : 0
                  }}
                </div>
              </div>
              <div class="shadow3_progress">
                <div class="flex">
                  <div class="shadow3_num">0</div>
                  <div class="shadow3_num shadow3_num1">100</div>
                </div>
                <div class="progress">
                  <div class="progress">
                    <div class="progress_content">
                      <div class="progress_hiden"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow shadow4 flex">
          <div>
            <div class="words">煤量优化模型</div>
            <div class="flex">
              <div class="column1">E04-FHH</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.ABY3,
                    'E04-FHH',
                    'ABY3',
                    infoList.ABY3_node_string
                  )
                "
              >
                {{ "ABY3" in infoList ? infoList.ABY3 : 0 }}
              </div>
            </div>
          </div>
          <div class="location1">
            <div class="flex">
              <div class="column1">E04-ZQPPV</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'BYMNL39',
                    infoList.BYMNL39_node_string,
                    'BYMNL39VV1',
                    '主蒸汽压力平均值'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.BYMNL39,
                    'BYMNL39',
                    infoList.BYMNL39_node_string,
                    'BYMNL39VV1'
                  )
                "
              >
                {{ "BYMNL39" in infoList ? infoList.BYMNL39 : 0 }}
              </div>
            </div>
            <div class="flex">
              <div class="column1">E04-FHL</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.ABY4,
                    'E04-FHL',
                    'ABY4',
                    infoList.ABY4_node_string
                  )
                "
              >
                {{ "ABY4" in infoList ? infoList.ABY4 : 0 }}
              </div>
            </div>
          </div>
        </div>
        <div class="shadow shadow5">
          <div class="words">床温-主汽压力系数</div>
          <div class="left flex">
            <div class="column1_one"></div>
            <div class="column1_two"></div>
            <div
              class="column1_three"
              @click="
                toIpt(
                  infoList.CWFH.IN_FD__ll__1__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__1__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.IN_FD__ll__1__rr__ : 0 }}
            </div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.CWFH.AV_FD__ll__1__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__1__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.AV_FD__ll__1__rr__ : 0 }}
            </div>
          </div>
          <div class="left flex">
            <div
              class="column1_three"
              @click="
                toIpt(
                  infoList.CWFH.IN_FD__ll__1__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__1__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.IN_FD__ll__1__rr__ : 0 }}
            </div>
            <div class="column1_two">---</div>
            <div
              class="column1_three"
              @click="
                toIpt(
                  infoList.CWFH.IN_FD__ll__2__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__2__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.IN_FD__ll__2__rr__ : 0 }}
            </div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.CWFH.AV_FD__ll__2__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__2__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.AV_FD__ll__2__rr__ : 0 }}
            </div>
          </div>
          <div class="left flex">
            <div
              class="column1_three"
              @click="
                toIpt(
                  infoList.CWFH.IN_FD__ll__2__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__2__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.IN_FD__ll__2__rr__ : 0 }}
            </div>
            <div class="column1_two">---</div>
            <div
              class="column1_three"
              @click="
                toIpt(
                  infoList.CWFH.IN_FD__ll__3__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__3__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.IN_FD__ll__3__rr__ : 0 }}
            </div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.CWFH.AV_FD__ll__3__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__3__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.AV_FD__ll__3__rr__ : 0 }}
            </div>
          </div>
          <div class="left flex">
            <div
              class="column1_three"
              @click="
                toIpt(
                  infoList.CWFH.IN_FD__ll__3__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__3__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.IN_FD__ll__3__rr__ : 0 }}
            </div>
            <div class="column1_two">---</div>
            <div
              class="column1_three"
              @click="
                toIpt(
                  infoList.CWFH.IN_FD__ll__4__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__4__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.IN_FD__ll__4__rr__ : 0 }}
            </div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.CWFH.AV_FD__ll__4__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__4__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.AV_FD__ll__4__rr__ : 0 }}
            </div>
          </div>
          <div class="left flex">
            <div class="column1_one">></div>
            <div class="column1_two"></div>
            <div
              class="column1_three"
              @click="
                toIpt(
                  infoList.CWFH.IN_FD__ll__4__rr__,
                  '床温-主汽压力系数',
                  'IN_FD__ll__4__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.IN_FD__ll__4__rr__ : 0 }}
            </div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.CWFH.AV_FD__ll__5__rr__,
                  '床温-主汽压力系数',
                  'AV_FD__ll__5__rr__',
                  infoList.CWFH_node_string
                )
              "
            >
              {{ "CWFH" in infoList ? infoList.CWFH.AV_FD__ll__5__rr__ : 0 }}
            </div>
          </div>
        </div>
      </div>
      <div class="two flex">
        <div class="first">
          <div class="shadow shadow1 float1 flex">
            <div class="column1">E07-JBML</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.GMLLSP,
                  'E07-JBML',
                  'GMLLSP',
                  infoList.GMLLSP_node_string
                )
              "
            >
              {{ "GMLLSP" in infoList ? infoList.GMLLSP : 0 }}
            </div>
          </div>
          <div class="float2 flex">
            <div
              class="buttonx"
              :style="{
                background:
                  infoList.XK04 && !infoList.XK04.SP ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.XK04
                  ? toDetail(
                      1,
                      'XK04',
                      infoList.XK04_node_string,
                      '',
                      '床温调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div class="shadow shadow1 location1 flex">
              <div class="column1">E01-MCWAV</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'AV',
                    infoList.XK04_node_string,
                    'XK04_AVVV1',
                    'XK04总输出',
                    'XK04'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.XK04.AV,
                    'AV',
                    infoList.XK04_node_string,
                    'XK04_AVVV1'
                  )
                "
              >
                {{ "XK04" in infoList ? infoList.XK04.AV : 0 }}
              </div>
            </div>
          </div>
          <div class="float3 flex">
            <div
              class="buttonx"
              :style="{
                background:
                  infoList.XK0O && !infoList.XK0O.SP ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.XK0O
                  ? toDetail(
                      1,
                      'XK0O',
                      infoList.XK0O_node_string,
                      '',
                      '主汽压力广义预测调节先控'
                    )
                  : ''
              "
            >
              X
            </div>
            <div class="shadow shadow1 location2 flex">
              <div class="column1">E14-MXXYSJ</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.MX_XYSJ,
                    'E14-MXXYSJ',
                    'MX_XYSJ',
                    infoList.MX_XYSJ_node_string
                  )
                "
              >
                {{ "MX_XYSJ" in infoList ? infoList.MX_XYSJ : 0 }}
              </div>
            </div>
          </div>
          <div class="float3 flex">
            <div
              class="buttonx"
              :style="{
                background:
                  infoList.XK0P && !infoList.XK0P.SP ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.XK0P
                  ? toDetail(
                      1,
                      'XK0P',
                      infoList.XK0P_node_string,
                      '',
                      '广义预测流量偏差调节先控'
                    )
                  : ''
              "
            >
              X
            </div>
            <div class="shadow shadow1 location2 flex">
              <div class="column1">E14-MXYCSJ</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.MX_YCSJ,
                    'E14-MXXYSJ',
                    'MX_YCSJ',
                    infoList.MX_YCSJ_node_string
                  )
                "
              >
                {{ "MX_YCSJ" in infoList ? infoList.MX_YCSJ : 0 }}
              </div>
            </div>
          </div>
          <div class="float4 flex">
            <div
              class="buttonx"
              :style="{
                background:
                  infoList.XK03 && !infoList.XK03.SP ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.XK03
                  ? toDetail(
                      1,
                      'XK03',
                      infoList.XK03_node_string,
                      '',
                      '主汽压力调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div class="shadow shadow1 location1 flex">
              <div class="column1">E02-ZQPAV</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'AV',
                    infoList.XK03_node_string,
                    'XK03_AVVV1',
                    'XK03总输出',
                    'XK03'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.XK03.AV,
                    'AV',
                    infoList.XK03_node_string,
                    'XK03_AVVV1'
                  )
                "
              >
                {{ "XK03" in infoList ? infoList.XK03.AV : 0 }}
              </div>
            </div>
          </div>
          <div class="float5 flex">
            <div
              class="buttonr"
              :style="{
                background:
                  infoList.QK07 && !infoList.QK07.TS ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.QK07
                  ? toDetail(
                      2,
                      'QK07',
                      infoList.QK07_node_string,
                      '',
                      '主汽流量前馈'
                    )
                  : ''
              "
            >
              K
            </div>
            <div class="shadow shadow1 location1 flex">
              <div class="column1">E08-PV4</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'FT108D_B',
                    infoList.FT108D_B_node_string,
                    'FT108D_BVV1',
                    '汽机电负荷'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FT108D_B,
                    'FT108D_B',
                    infoList.FT108D_B_node_string,
                    'FT108D_BVV1'
                  )
                "
              >
                {{ "FT108D_B" in infoList ? infoList.FT108D_B : 0 }}
              </div>
            </div>
          </div>
          <div class="float6 flex">
            <div>
              <div class="flex">
                <div class="column1">E03-OE</div>
                <div
                  class="column3"
                  @click="
                    toCompon(
                      2,
                      'OBCS',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'OE2VV1',
                      '优化过程上次值',
                      'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL.OBCS,
                      'OBCS',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'OE2VV1'
                    )
                  "
                >
                  {{
                    "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL" in infoList
                      ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL
                          .OBCS
                      : 0
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="column1">E03-NE</div>
                <div
                  class="column3"
                  @click="
                    toCompon(
                      2,
                      'NBCS',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'NE2VV1',
                      '优化过程本次值',
                      'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL.NBCS,
                      'NBCS',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'NE2VV1'
                    )
                  "
                >
                  {{
                    "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL" in infoList
                      ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL
                          .NBCS
                      : 0
                  }}
                </div>
              </div>
            </div>
            <div class="button3">
              <div
                class="button3_word"
                @click="
                  toIpt(
                    infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.RESET,
                    '按钮',
                    'RESET',
                    infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL_node_string
                  )
                "
              >
                {{
                  "BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL" in infoList
                    ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.RESET
                      ? "强制优化"
                      : "自动优化"
                    : 0
                }}
              </div>
            </div>
          </div>
          <div class="float7 flex">
            <div
              class="buttonr"
              :style="{
                background:
                  infoList.RSF03 && !infoList.RSF03.SP ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.RSF03
                  ? toDetail(
                      3,
                      'RSF03',
                      infoList.RSF03_node_string,
                      '',
                      '运行炉压力软伺服'
                    )
                  : ''
              "
            >
              R
            </div>
            <div class="shadow shadow1 location1 flex">
              <div class="column1">E04-FHAV</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'AV',
                    infoList.RSF03_node_string,
                    'RSF03_AVVV1',
                    'RSF03输出值',
                    'RSF03'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.RSF03.AV,
                    'AV',
                    infoList.RSF03_node_string,
                    'RSF03_AVVV1'
                  )
                "
              >
                {{ "RSF03" in infoList ? infoList.RSF03.AV : 0 }}
              </div>
            </div>
          </div>
          <div class="float8 flex">
            <div
              class="buttonr"
              :style="{
                background:
                  infoList.RSF0C && !infoList.RSF0C.SP ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.RSF0C
                  ? toDetail(
                      3,
                      'RSF0C',
                      infoList.RSF0C_node_string,
                      '',
                      '软伺服'
                    )
                  : ''
              "
            >
              R
            </div>
            <div class="words location1">多炉协调控制模型</div>
          </div>
          <div class="shadow shadow2">
            <div class="words">前馈脉冲重叠模型</div>
            <div class="flex">
              <div class="column1">E05-KG</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.QKMCFOP1.KG,
                    'E05-KG',
                    'KG',
                    infoList.QKMCFOP1_node_string
                  )
                "
              >
                <!-- {{ '' in infoList?infoList.QKMCFOP1.KG:0}} -->0.00
              </div>
            </div>
            <div class="flex">
              <div class="column1">E05-TC</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.QKMCFOP1.TC,
                    'E05-TC',
                    'TC',
                    infoList.QKMCFOP1_node_string
                  )
                "
              >
                <!-- {{ '' in infoList?infoList.QKMCFOP1.TC:0}} -->0.00
              </div>
            </div>
            <div class="flex">
              <div class="column1">E05-DI</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.QKMCFOP1.DI,
                    'E05-DI',
                    'DI',
                    infoList.QKMCFOP1_node_string
                  )
                "
              >
                <!-- {{ '' in infoList?infoList.QKMCFOP1.DI:0}} -->0.00
              </div>
            </div>
            <div class="flex">
              <div class="column1">E05-AV</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'AV',
                    infoList.QKMCFOP1_node_string,
                    'QKMCFOP_AVVV1',
					'',
					'QKMCFOP1'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.QKMCFOP1.AV,
                    'AV',
                    infoList.QKMCFOP1_node_string,
                    'QKMCFOP_AVVV1'
                  )
                "
              >
                <!-- {{ 'QKMCFOP1' in infoList?infoList.QKMCFOP1.AV:0}} -->
				  0.00
              </div>
            </div>
          </div>
        </div>
        <div class="second">
          <div class="button1 float1">
            <div
              class="button1_word"
              @click="
                toIpt(infoList.XTEN, '按钮', 'XTEN', infoList.XTEN_node_string)
              "
            >
              {{ "XTEN" in infoList ? (infoList.XTEN ? "投用" : "切除") : 0 }}
            </div>
          </div>
          <div class="button1 float2">
            <div
              class="button1_word"
              @click="
                toIpt(infoList.GYYC, '按钮', 'GYYC', infoList.GYYC_node_string)
              "
            >
              {{
                "GYYC" in infoList
                  ? infoList.GYYC
                    ? "预测投用"
                    : "预测切除"
                  : 0
              }}
            </div>
          </div>
          <div class="button1 float3">
            <div
              class="button1_word"
              @click="
                toIpt(infoList.XTEN, '按钮', 'XTEN', infoList.XTEN_node_string)
              "
            >
              {{ "XTEN" in infoList ? (infoList.XTEN ? "投用" : "切除") : 0 }}
            </div>
          </div>
          <div class="button1 float4">
            <div
              class="button1_word"
              @click="
                toIpt(
                  infoList.BYKGL28,
                  '按钮',
                  'BYKGL28',
                  infoList.BYKGL28_node_string
                )
              "
            >
              {{
                "BYKGL28" in infoList ? (infoList.BYKGL28 ? "投用" : "切除") : 0
              }}
            </div>
          </div>
          <div class="button1 float5">
            <div
              class="button1_word"
              @click="
                toIpt(
                  infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr__.BCSYHQY,
                  '按钮',
                  'BCSYHQY',
                  infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr___node_string
                )
              "
            >
              {{
                "BCSYH_SHM1__p__BCSYHn__ll__2__rr__" in infoList
                  ? infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr__.BCSYHQY
                    ? "优化投用"
                    : "优化切除"
                  : 0
              }}
            </div>
          </div>
          <div class="button1 float6">
            <div
              class="button1_word"
              @click="
                toIpt(infoList.XTEN, '按钮', 'XTEN', infoList.XTEN_node_string)
              "
            >
              {{
                "XTEN" in infoList ? (infoList.XTEN ? "调节炉" : "运行炉") : 0
              }}
            </div>
          </div>
          <div class="button1 float7">
            <div
              class="button1_word"
              @click="
                toIpt(
                  infoList.GLXTEN,
                  '按钮',
                  'GLXTEN',
                  infoList.GLXTEN_node_string
                )
              "
            >
              {{
                "GLXTEN" in infoList
                  ? infoList.GLXTEN
                    ? "多炉协调"
                    : "协调切除"
                  : 0
              }}
            </div>
          </div>
          <div class="button1 float8">
            <div
              class="button1_word"
              @click="
                toIpt(
                  infoList.QKMCFOPEN,
                  '按钮',
                  'QKMCFOPEN',
                  infoList.QKMCFOPEN_node_string
                )
              "
            >
              {{
                "QKMCFOPEN" in infoList
                  ? infoList.QKMCFOPEN
                    ? "多炉协调"
                    : "协调切除"
                  : 0
              }}
            </div>
          </div>
        </div>
        <div class="third">
          <div class="shadow shadow1 flex">
            <div class="column1">E12-MLTC</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.TLB01,
                  'E12-MLTC',
                  'TLB01',
                  infoList.TLB01_node_string
                )
              "
            >
              {{ "TLB01" in infoList ? infoList.TLB01 : 0 }}
            </div>
          </div>
          <div class="shadow shadow1 flex">
            <div class="column1">E12-MLPV</div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'PV',
                  infoList.PID08_node_string,
                  'PID08_PVVV1',
                  'PID08测量值',
                  'PID08'
                )
              "
              @dblclick="
                Cclick(
                  infoList.PID08.PV,
                  'PV',
                  infoList.PID08_node_string,
                  'PID08_PVVV1'
                )
              "
            >
              {{ "PID08" in infoList ? infoList.PID08.PV : 0 }}
            </div>
          </div>
          <div class="shadow shadow1 flex">
            <div class="column1">E12-MLSP</div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'SP',
                  infoList.PID08_node_string,
                  'PID08_SPVV1',
                  '给煤流量调节',
                  'PID08'
                )
              "
              @dblclick="
                Cclick(
                  infoList.PID08.SP,
                  'SP',
                  infoList.PID08_node_string,
                  'PID08_SPVV1'
                )
              "
            >
              {{ "PID08" in infoList ? infoList.PID08.SP : 0 }}
            </div>
          </div>
          <div class="shadow shadow2">
            <div class="words">氧量纠偏模型</div>
            <div class="right flex">
              <div class="column1">E10-YCC</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.O2PC_SP,
                    'E10-YCC',
                    'O2PC_SP',
                    infoList.O2PC_SP_node_string
                  )
                "
              >
                {{ "O2PC_SP" in infoList ? infoList.O2PC_SP : 0 }}
              </div>
            </div>
            <div class="right flex">
              <div class="column1">E10-YCT</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.O2PC_T,
                    'E10-YCT',
                    'O2PC_T',
                    infoList.O2PC_T_node_string
                  )
                "
              >
                {{ "O2PC_T" in infoList ? infoList.O2PC_T : 0 }}
              </div>
            </div>
            <div class="right flex">
              <div class="column1">E10-YCS</div>
              <div
                class="box1"
                :style="{
                  background: infoList.SEL_O2 == false ? '#2AFC30' : 'red',
                }"
                @click="
                  toIpt(
                    infoList.O2BYC,
                    '按钮',
                    'O2BYC',
                    infoList.SEL_O2_node_string
                  )
                "
              ></div>
            </div>
            <div class="right flex">
              <div class="column1">E10-LJZ</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'AT101AD_B',
                    infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1',
                    '烟气含氧量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.AT101AD_B,
                    'AT101AD_B',
                    infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1'
                  )
                "
              >
                {{ "AT101AD_B" in infoList ? infoList.AT101AD_B : 0 }}
              </div>
            </div>
            <div class="right flex">
              <div class="column1">E10-RJZ</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'AT101BD_B',
                    infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1',
                    '烟气含氧量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.AT101BD_B,
                    'AT101BD_B',
                    infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1'
                  )
                "
              >
                {{ "AT101BD_B" in infoList ? infoList.AT101BD_B : 0 }}
              </div>
            </div>
            <div class="button3 flex">
              <div class="column1">E10-LOT</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'GMO2OUT_L',
                    infoList.GMO2OUT_L_node_string,
                    'GMO2OUT1VV1',
                    '床温氧量输出左给煤偏置'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.GMO2OUT_L,
                    'GMO2OUT_L',
                    infoList.GMO2OUT_L_node_string,
                    'GMO2OUT1VV1'
                  )
                "
              >
                {{ "GMO2OUT_L" in infoList ? infoList.GMO2OUT_L : 0 }}
              </div>
            </div>
            <div class="button3 flex">
              <div class="column1">E10-ROT</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'GMO2OUT_R',
                    infoList.GMO2OUT_R_node_string,
                    'GMO2OUT2VV1',
                    '床温氧量输出右给煤偏置'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.GMO2OUT_R,
                    'GMO2OUT_R',
                    infoList.GMO2OUT_R_node_string,
                    'GMO2OUT2VV1'
                  )
                "
              >
                {{ "GMO2OUT_R" in infoList ? infoList.GMO2OUT_R : 0 }}
              </div>
            </div>
            <div class="flex">
              <div class="button1 float1">
                <div
                  class="button1_word"
                  @click="
                    toIpt(
                      infoList.GMO2QT,
                      '按钮',
                      'GMO2QT',
                      infoList.GMO2QT_node_string
                    )
                  "
                >
                  {{
                    "GMO2QT" in infoList
                      ? infoList.GMO2QT
                        ? "投用"
                        : "切除"
                      : 0
                  }}
                </div>
              </div>
              <div
                class="buttonr float2"
                :style="{
                  background:
                    infoList.RSF09 && !infoList.RSF09.SP ? '#2AFC30' : 'red',
                }"
                @click="
                  infoList.RSF09
                    ? toDetail(
                        3,
                        'RSF09',
                        infoList.RSF09_node_string,
                        '',
                        '氧量纠偏软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
            </div>
          </div>
          <div class="shadow shadow2 float3">
            <div class="words">床温纠偏模型</div>
            <div class="right flex">
              <div class="column1">E11-YCC2</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.CWZYPC_SP,
                    'E11-YCC2',
                    'CWZYPC_SP',
                    infoList.CWZYPC_SP_node_string
                  )
                "
              >
                {{ "CWZYPC_SP" in infoList ? infoList.CWZYPC_SP : 0 }}
              </div>
            </div>
            <div class="right flex">
              <div class="column1">E11-YCT2</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.CWZYPC_T,
                    'E11-YCT2',
                    'CWZYPC_T',
                    infoList.CWZYPC_T_node_string
                  )
                "
              >
                {{ "CWZYPC_T" in infoList ? infoList.CWZYPC_T : 0 }}
              </div>
            </div>
            <div class="right flex">
              <div class="column1">E11-YCS2</div>
              <div
                class="box1"
                :style="{
                  background: infoList.CWPCYC == false ? '#2AFC30' : 'red',
                }"
                @click="
                  toIpt(
                    infoList.CWPCYC,
                    '按钮',
                    'CWPCYC',
                    infoList.CWPCYC_node_string
                  )
                "
              ></div>
            </div>
            <div class="right flex">
              <div class="column1">E11-LJZ2</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'CWJZ_Z',
                    infoList.CWJZ_Z_node_string,
                    'CWJZ_ZVV1',
                    '左侧床温均值'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.CWJZ_Z,
                    'CWJZ_Z',
                    infoList.CWJZ_Z_node_string,
                    'CWJZ_ZVV1'
                  )
                "
              >
                {{ "CWJZ_Z" in infoList ? infoList.CWJZ_Z : 0 }}
              </div>
            </div>
            <div class="right flex">
              <div class="column1">E11-RJZ2</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'CWJZ_Y',
                    infoList.CWJZ_Y_node_string,
                    'CWJZ_YVV1',
                    '右侧床温均值'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.CWJZ_Y,
                    'CWJZ_Y',
                    infoList.CWJZ_Y_node_string,
                    'CWJZ_YVV1'
                  )
                "
              >
                {{ "CWJZ_Y" in infoList ? infoList.CWJZ_Y : 0 }}
              </div>
            </div>
            <div class="button3 flex">
              <div class="column1">E11-LOT2</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'GMCWOUT_L',
                    infoList.GMCWOUT_L_node_string,
                    'GMCWOUT1VV1',
                    '床温偏差给煤左偏置修正'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.GMCWOUT_L,
                    'GMCWOUT_L',
                    infoList.GMCWOUT_L_node_string,
                    'GMCWOUT1VV1'
                  )
                "
              >
                {{ "GMCWOUT_L" in infoList ? infoList.GMCWOUT_L : 0 }}
              </div>
            </div>
            <div class="button3 flex">
              <div class="column1">E11-ROT2</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'GMCWOUT_R',
                    infoList.GMCWOUT_R_node_string,
                    'GMCWOUT2VV1',
                    '床温偏差给煤右偏置修正'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.GMCWOUT_R,
                    'GMCWOUT_R',
                    infoList.GMCWOUT_R_node_string,
                    'GMCWOUT2VV1'
                  )
                "
              >
                {{ "GMCWOUT_R" in infoList ? infoList.GMCWOUT_R : 0 }}
              </div>
            </div>
            <div class="flex">
              <div class="button1 float1">
                <div
                  class="button1_word"
                  @click="
                    toIpt(
                      infoList.GMCWQT,
                      '按钮',
                      'GMCWQT',
                      infoList.GMCWQT_node_string
                    )
                  "
                >
                  {{
                    "GMCWQT" in infoList
                      ? infoList.GMCWQT
                        ? "投用"
                        : "切除"
                      : 0
                  }}
                </div>
              </div>
              <div
                class="buttonr float2"
                :style="{
                  background:
                    infoList.RSF02 && !infoList.RSF02.SP ? '#2AFC30' : 'red',
                }"
                @click="
                  infoList.RSF02
                    ? toDetail(
                        3,
                        'RSF02',
                        infoList.RSF02_node_string,
                        '',
                        '床温纠偏软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
            </div>
          </div>
        </div>
        <div class="fourth">
          <div class="button2 float1">
            <div class="button2_word">给煤软测量模型</div>
          </div>
          <div
            class="buttonx float2"
            :style="{
              background:
                infoList.XK02 && !infoList.XK02.SP ? '#2AFC30' : 'red',
            }"
            @click="
              infoList.XK02
                ? toDetail(
                    1,
                    'XK02',
                    infoList.XK02_node_string,
                    '',
                    '给煤流量调节'
                  )
                : ''
            "
          >
            X
          </div>
        </div>
        <div class="fifth">
          <div class="shadow shadow1 float1 flex">
            <div class="column1">E13-MZTJSJ</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.TMLLB,
                  'E13-MZTJSJ',
                  'TMLLB',
                  infoList.TMLLB_node_string
                )
              "
            >
              {{ "TMLLB" in infoList ? infoList.TMLLB : 0 }}
            </div>
          </div>
          <div class="shadow shadow1 float2 flex">
            <div class="column1">断煤流量设定</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.DMLL,
                  '断煤流量设定',
                  'DMLL',
                  infoList.DMLL_node_string
                )
              "
            >
              {{ "DMLL" in infoList ? infoList.DMLL : 0 }}
            </div>
          </div>
        </div>
      </div>
      <div class="three">
        <div class="button2">
          <div
            class="button2_word"
            @click="
              toIpt(infoList.QTDM, '按钮', 'QTDM', infoList.QTDM_node_string)
            "
          >
            {{
              "QTDM" in infoList
                ? infoList.QTDM
                  ? "断煤处理启用"
                  : "断煤处理切除"
                : 0
            }}
          </div>
        </div>
        <div class="shadow shadow1 float1 flex">
          <div class="column1">E12-AV</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'AV',
                infoList.XK02_node_string,
                'XK02_AVVV1',
                'XK02总输出',
                'XK02'
              )
            "
            @dblclick="
              Cclick(
                infoList.XK02.AV,
                'AV',
                infoList.XK02_node_string,
                'XK02_AVVV1'
              )
            "
          >
            {{ "XK02" in infoList ? infoList.XK02.AV : 0 }}
          </div>
        </div>
        <div class="shadow shadow1 float2 flex">
          <div class="column1">E12-A01</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'KF_GM1_B',
                infoList.KF_GM1_B_node_string,
                'KF_GM1_BVV1',
                '1#给煤机反馈'
              )
            "
            @dblclick="
              Cclick(
                infoList.KF_GM1_B,
                'KF_GM1_B',
                infoList.KF_GM1_B_node_string,
                'KF_GM1_BVV1'
              )
            "
          >
            {{ "KF_GM1_B" in infoList ? infoList.KF_GM1_B : 0 }}
          </div>
        </div>
        <div class="shadow shadow1 float3 flex">
          <div class="column1">E12-A02</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'KF_GM2_B',
                infoList.KF_GM2_B_node_string,
                'KF_GM2_BVV1',
                '2#给煤机反馈'
              )
            "
            @dblclick="
              Cclick(
                infoList.KF_GM2_B,
                'KF_GM2_B',
                infoList.KF_GM2_B_node_string,
                'KF_GM2_BVV1'
              )
            "
          >
            {{ "KF_GM2_B" in infoList ? infoList.KF_GM2_B : 0 }}
          </div>
        </div>
        <div class="shadow shadow1 float4 flex">
          <div class="column1">E12-A03</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'KF_GM3_B',
                infoList.KF_GM3_B_node_string,
                'KF_GM3_BVV1',
                '3#给煤机反馈'
              )
            "
            @dblclick="
              Cclick(
                infoList.KF_GM3_B,
                'KF_GM3_B',
                infoList.KF_GM3_B_node_string,
                'KF_GM3_BVV1'
              )
            "
          >
            {{ "KF_GM3_B" in infoList ? infoList.KF_GM3_B : 0 }}
          </div>
        </div>
        <div
          class="buttonx float5"
          :style="{
            background:
              'MAN1' in infoList
                ? infoList.MAN1.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN2' in infoList
                ? infoList.MAN2.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN3' in infoList
                ? infoList.MAN3.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN4' in infoList
                ? infoList.MAN4.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN23' in infoList
                ? infoList.MAN23.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red' || 'MAN24' in infoList
                ? infoList.MAN24.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red',
          }"
          @click="toCompon(0, 'CFB_MANGM', 6)"
        >
          A
        </div>
        <div class="shadow shadow1 float6 flex">
          <div class="column1">E12-A04</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'KF_GM4_B',
                infoList.KF_GM4_B_node_string,
                'KF_GM4_BVV1',
                '4#给煤机反馈'
              )
            "
            @dblclick="
              Cclick(
                infoList.KF_GM4_B,
                'KF_GM4_B',
                infoList.KF_GM4_B_node_string,
                'KF_GM4_BVV1'
              )
            "
          >
            {{ "KF_GM4_B" in infoList ? infoList.KF_GM4_B : 0 }}
          </div>
        </div>
        <div class="shadow shadow1 float7 flex">
          <div class="column1">E12-A05</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'KF_GM5_B',
                infoList.KF_GM5_B_node_string,
                'KF_GM5_BVV1',
                '5#给煤机反馈'
              )
            "
            @dblclick="
              Cclick(
                infoList.KF_GM5_B,
                'KF_GM5_B',
                infoList.KF_GM5_B_node_string,
                'KF_GM5_BVV1'
              )
            "
          >
            {{ "KF_GM5_B" in infoList ? infoList.KF_GM5_B : 0 }}
          </div>
        </div>
        <div class="shadow shadow1 float8 flex">
          <div class="column1">E12-A06</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'KF_GM6_B',
                infoList.KF_GM6_B_node_string,
                'KF_GM6_BVV1',
                '6#给煤机反馈'
              )
            "
            @dblclick="
              Cclick(
                infoList.KF_GM6_B,
                'KF_GM6_B',
                infoList.KF_GM6_B_node_string,
                'KF_GM6_BVV1'
              )
            "
          >
            {{ "KF_GM6_B" in infoList ? infoList.KF_GM6_B : 0 }}
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
    <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <manyManual
      v-if="manyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      @sendStatus="isClose"
      :infoList="infoList"
    ></manyManual>
  </div>
</template>
<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import manyManual from "@/components/manyManual1.vue"; //手操器组件
export default {
  name: "CfbParameter4",
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  components: {
    inputVal,
    Historical,
    manyManual,
  },
  data: () => {
    return {
      chName: "",
      manyManual: false,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
      node1: "",
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
        console.log(this.infoList);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "Cfbkfirstcontol";
            break;
          case 3:
            pathname = "CfbRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "Parameter04", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
        case "manyManual":
          return (this.manyManual = val1);
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.manyManual = true),
              (this.Manualtitname = name),
              (this.Manualnode = name2)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (
              (this.isHshow = true),
              (this.chName = name4),
              (this.node1 = titname)
            );
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
#CfbParameter4 {
  width: 83vw;
  height: 91vh;
  background-image: url("~@/assets/images/CfbBoiler/para071_bg.png");
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -45.5vh 0 0 -40.6vw;
  z-index: 999;

  .title-hang {
    width: 83vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    margin-left: 15vw;
  }

  .title {
    width: 19vw;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
    margin-top: 1vh;
    text-align: center;
    margin-left: 16.8vw;
  }

  .icon {
    width: 1.8vw;
    height: 3.2vh;
    background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
    background-size: 100% 100%;
    margin-left: 29vw;
    margin-top: 2vh;
  }

  .main {
    width: 83vw;
    height: 3vh;
    margin-top: 4vh;

    .main_title {
      font-family: PingFang-SC-Regular;
      font-size: 3vh;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 1vh;
      color: #d5fffe;
      text-align: center;
    }
  }

  .context {
    width: 80vw;
    height: 71vh;
    margin-top: 4vh;
    margin-left: 1.88vw;
    font-family: PingFang-SC-Regular;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vw;

    .shadow {
      background-color: #001b2860;
      box-shadow: 0vw 0vw 0vw 0vw #15384c;
      border-radius: 0vw;
      border-radius: 1px;
      border: solid 1px #236f8d;
      font-size: 1.2vh;
      line-height: 2.3vh;

      .words {
        font-size: 1.39vh;
        line-height: 1.85vh;
        color: #2fc3e3;
        margin-left: 0.8vw;
      }

      .column1 {
        width: 6vw;
        color: #8aeaff;
        margin-left: 0.5vw;
      }

      .column2 {
        width: 2vw;
        color: #00ffb4;
        text-align: right;cursor: pointer;
      }

      .column3 {
        width: 2vw;
        color: #00e4ff;
        text-align: right;cursor: pointer;
      }
    }

    .one {
      margin-top: -1vh;
      z-index: 1;

      .shadow1 {
        width: 8vw;
        height: 9vh;

        .column1 {
          width: 4.8vw;
          color: #8aeaff;
          margin-left: 0.5vw;
        }

        .column2 {
          width: 2vw;
          color: #00ffb4;
          text-align: right;cursor: pointer;
        }
      }

      .shadow2 {
        width: 10vw;
        height: 2.81vh;
      }

      .location2 {
        margin-top: 3.15vh;
      }

      .shadow3 {
        width: 18.23vw;
        height: 20.37vh;
        margin-top: 3vh;

        .location1 {
          margin-left: 1.8vw;
        }

        .column1 {
          width: 4.8vw;
          color: #8aeaff;
          margin-left: 0.5vw;
        }

        .column2 {
          width: 2vw;
          color: #00ffb4;
          text-align: right;cursor: pointer;
        }

        .column3 {
          width: 2vw;
          color: #00e4ff;
          text-align: right;cursor: pointer;
        }

        .shadow3_progress {
          margin-left: 0vw;
          margin-top: 0.6vh;

          .shadow3_num {
            line-height: 1.8vh;
            width: 2.5vw;
            color: #19aec4;
          }

          .shadow3_num1 {
            line-height: 1.8vh;
            width: 5.8vw;
            text-align: right;
            color: #19aec4;
          }

          .progress {
            width: 8vw;
            height: 1vh;
            background-color: #8aeaff;

            .progress_hiden {
              width: 6.4vw;
              height: 1vh;
              background-color: #00ffb4;
            }
          }
        }
      }

      .shadow4 {
        width: 18.23vw;
        height: 5.56vh;
        margin-top: 0.83vh;

        .words {
          font-size: 1.39vh;
          line-height: 2.3vh;
          color: #2fc3e3;
          margin-left: 0.5vw;
        }

        .location1 {
          margin-left: 1.8vw;
        }

        .column1 {
          width: 4.8vw;
          color: #8aeaff;
          margin-left: 0.5vw;
        }

        .column2 {
          width: 2vw;
          color: #00ffb4;
          text-align: right;cursor: pointer;
        }

        .column3 {
          width: 2vw;
          color: #00e4ff;
          text-align: right;cursor: pointer;
        }
      }

      .shadow5 {
        width: 12.5vw;
        height: 14.81vh;
        margin-top: 2.78vh;

        .words {
          text-align: center;
          font-size: 1.39vh;
          line-height: 2.3vh;
          color: #2fc3e3;
        }

        .left {
          margin-left: 1vw;
        }

        .column1_one {
          width: 2vw;
          color: #8aeaff;
          text-align: center;
        }

        .column1_two {
          width: 3vw;
          color: #8aeaff;
          text-align: center;
        }

        .column1_three {
          width: 2vw;
          color: #01ffba;
          text-align: center;cursor: pointer;
        }

        .column2 {
          width: 3vw;
          color: #01ffba;
          text-align: right;cursor: pointer;
        }
      }
    }

    .two {
      width: 54vw;
      height: 66vh;
      background-image: url("~@/assets/images/CfbBoiler/para041.png");
      background-size: 100% 100%;
      margin-left: -0.5vw;

      .buttonx {
        width: 1.3vw;
        height: 2.2vh;
        border: 2px solid rgb(217, 243, 145);
        text-align: center;
        line-height: 2vh;
        background-color: #00e4ff;
        color: white;cursor: pointer;
      }

      .buttonr {
        width: 1.3vw;
        height: 2.2vh;
        border: 2px solid rgb(217, 243, 145);
        text-align: center;
        line-height: 2vh;
        background-color: #04ff57;
        color: white;cursor: pointer;
      }

      .button1 {
        width: 3.13vw;
        height: 1.85vh;
        background-color: #22fff7;
        border-radius: 1px;
        text-align: center;

        .button1_word {
          font-size: 1.2vh;
          line-height: 1.9vh;
          color: #0a4c62;
          cursor: pointer;
        }
      }

      .button2 {
        width: 7.29vw;
        height: 2.3vh;
        background-color: #22fff7;
        border-radius: 1px;
        text-align: center;

        .button2_word {
          font-size: 1.6vh;
          line-height: 2vh;
          color: #0a4c62;
        }
      }

      .first {
        .shadow1 {
          width: 8.85vw;
          height: 2.78vh;
        }

        .float1 {
          margin-left: 5.21vw;
          margin-top: -1.3vh;
        }

        .float2 {
          margin-left: 2.4vw;
          margin-top: 3.6vh;

          .location1 {
            margin-left: 1.3vw;
          }
        }

        .float3 {
          margin-left: 3.3vw;

          .location2 {
            margin-left: 0.3vw;
          }
        }

        .float4 {
          margin-left: 2.4vw;
          margin-top: 3.4vh;

          .location1 {
            margin-left: 1.3vw;
          }
        }

        .float5 {
          margin-left: 2.4vw;
          margin-top: 3.2vh;

          .location1 {
            margin-left: 1.3vw;
          }
        }

        .float6 {
          margin-left: 0.99vw;
          margin-top: 7.72vh;

          .column1 {
            width: 4vw;
            color: #8aeaff;
            margin-left: 0.5vw;
          }

          .column3 {
            width: 2vw;
            color: #00e4ff;
            text-align: right;
            cursor: pointer;
          }

          .button3 {
            width: 4vw;
            height: 2vh;
            line-height: 2vh;
            border-radius: 1px;
            border: solid 1px #0bd0c9;
            text-align: center;
            margin-top: 2.31vh;
            margin-left: 2vw;

            .button3_word {
              color: #22fff7;cursor: pointer;
            }
          }
        }

        .float7 {
          margin-left: 2.4vw;
          margin-top: 6.3vh;

          .location1 {
            margin-left: 1.3vw;
          }
        }

        .float8 {
          margin-left: 2.4vw;
          margin-top: 3.84vh;

          .words {
            margin-left: 1vw;
            margin-top: -1.5vh;
            color: #2fc3e3;
          }
        }

        .shadow2 {
          width: 8.91vw;
          height: 11.11vh;
          margin-left: 5.16vw;
          margin-top: 0.3vh;
        }
      }

      .second {
        margin-left: 0.78vw;

        .float1 {
          margin-top: 4.17vh;
        }

        .float2 {
          margin-top: 4.33vh;
        }

        .float3 {
          margin-top: 4.33vh;
        }

        .float4 {
          margin-top: 4.33vh;
        }

        .float5 {
          margin-top: 13.13vh;
        }

        .float6 {
          margin-top: 5.56vh;
        }

        .float7 {
          margin-top: 4.91vh;
        }

        .float8 {
          margin-top: 9.81vh;
        }
      }

      .third {
        margin-top: -1vh;
        margin-left: 4.64vw;

        .box1 {
          width: 3vw;
          height: 1.8vh;
          background-color: #00ffb4;
          margin-left: -0.5vw;
          margin-top: 0.2vh;
        }

        .shadow1 {
          width: 8.85vw;
          height: 2.78vh;

          .words {
            text-align: center;
          }

          .column1 {
            width: 5vw;
            color: #8aeaff;
            margin-left: 0.5vw;
          }

          .column2 {
            width: 2vw;
            color: #00ffb4;
            text-align: right;cursor: pointer;
          }

          .column3 {
            width: 2vw;
            color: #00e4ff;
            text-align: right;
            cursor: pointer;
          }
        }

        .shadow2 {
          width: 8.85vw;
          height: 22.22vh;

          .words {
            text-align: center;
          }

          .column1 {
            width: 5vw;
            color: #8aeaff;
            margin-left: 0.5vw;
          }

          .column2 {
            width: 2vw;
            color: #00ffb4;
            text-align: right;cursor: pointer;
          }

          .column3 {
            width: 2vw;
            color: #00e4ff;
            text-align: right;
            cursor: pointer;
          }

          .right {
            margin-left: 0.4vw;
          }

          .button3 {
            width: 8vw;
            height: 2vh;
            line-height: 2vh;
            border-radius: 1px;
            border: solid 1px #0bd0c9;
            margin-top: 0vh;
            margin-left: 0.4vw;

            .button3_word {
              color: #22fff7;
            }
          }
        }

        .float1 {
          margin-top: 1.37vh;
          margin-left: 1.25vw;
        }

        .float2 {
          margin-left: 3.03vh;
          margin-top: 1.1vh;
        }

        .float3 {
          margin-top: 15vh;
        }
      }

      .fourth {
        .float1 {
          margin-left: 1.51vw;
        }

        .float2 {
          margin-left: 3.71vw;
          margin-top: 3.3vh;
        }
      }

      .fifth {
        .shadow1 {
          width: 8.85vw;
          height: 2.78vh;
        }

        .float1 {
          margin-left: 2.66vw;
          margin-top: -3.89vh;
        }

        .float2 {
          margin-left: 2.66vw;
          margin-top: 0.83vh;
        }
      }
    }

    .three {
      .button2 {
        width: 7.29vw;
        height: 2.3vh;
        background-color: #22fff7;
        border-radius: 1px;
        text-align: center;
        margin-left: 0.5vw;

        .button2_word {
          font-size: 1.6vh;
          line-height: 2vh;
          color: #0a4c62;cursor: pointer;
        }
      }

      .buttonx {
        width: 1.3vw;
        height: 2.2vh;
        border: 2px solid rgb(217, 243, 145);
        text-align: center;
        line-height: 2vh;
        background-color: #00e4ff;cursor: pointer;
      }

      .shadow1 {
        width: 7.81vw;
        height: 2.78vh;

        .column1 {
          width: 4.5vw;
          color: #8aeaff;
          margin-left: 0.5vw;
        }

        .column2 {
          width: 2vw;
          color: #00ffb4;
          text-align: right;cursor: pointer;
        }
      }

      .float1 {
        margin-top: 2.8vh;
      }

      .float2 {
        margin-top: 3.24vh;
      }

      .float3 {
        margin-top: 3.24vh;
      }

      .float4 {
        margin-top: 3.61vh;
      }

      .float5 {
        margin-top: 6.76vh;
      }

      .float6 {
        margin-top: 4.8vh;
      }

      .float7 {
        margin-top: 6.36vh;
      }

      .float8 {
        margin-top: 7vh;
      }
    }
  }
}
</style>
